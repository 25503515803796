var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.inited
    ? _c(
        "div",
        { staticClass: "store-product-edit" },
        [
          _c(
            "context-title",
            {
              attrs: {
                title: _vm.pageTitle,
                passedActions: _vm.primaryTitleBarActions,
              },
            },
            [
              _c(
                "template",
                { slot: "secondary" },
                [
                  _c(
                    "v-row",
                    {
                      staticClass:
                        "pl-5 pr-5 justify-space-between align-items-center",
                      attrs: { "no-gutters": "" },
                    },
                    [
                      _c("toggle", {
                        staticClass: "d-flex",
                        attrs: { slider: "true", label: "Enabled" },
                        model: {
                          value: _vm.product.inventoryItemAvailable,
                          callback: function ($$v) {
                            _vm.$set(_vm.product, "inventoryItemAvailable", $$v)
                          },
                          expression: "product.inventoryItemAvailable",
                        },
                      }),
                      _c(
                        "div",
                        { staticClass: "d-flex" },
                        [
                          _vm.canEdit
                            ? _c(
                                "text-button",
                                {
                                  attrs: { type: "delete" },
                                  nativeOn: {
                                    click: function ($event) {
                                      _vm.deleteModalActive = true
                                    },
                                  },
                                },
                                [_vm._v(" Delete product ")]
                              )
                            : _vm._e(),
                          _c(
                            "ActionButton",
                            {
                              attrs: {
                                type: "faded",
                                noIcon: true,
                                action: () =>
                                  _vm.gotoPreviousItem(_vm.productId, true),
                              },
                            },
                            [_vm._v("View Previous")]
                          ),
                          _c(
                            "ActionButton",
                            {
                              attrs: {
                                type: "faded",
                                noIcon: true,
                                action: _vm.leave,
                              },
                            },
                            [_vm._v("Close")]
                          ),
                          _vm.hasNextItem(_vm.productId)
                            ? _c(
                                "ActionButton",
                                {
                                  attrs: {
                                    type: "faded",
                                    noIcon: true,
                                    action: () =>
                                      _vm.gotoNextItem(_vm.productId, true),
                                  },
                                },
                                [_vm._v("View Next")]
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            2
          ),
          _c("span", { staticClass: "edit-bar" }),
          _vm.comboBanner
            ? _c("span", { staticClass: "combo-banner" }, [
                _vm._v(" " + _vm._s(_vm.comboBanner)),
              ])
            : _vm._e(),
          _c("product-form", {
            attrs: {
              value: _vm.product,
              modSets: _vm.modSets,
              products: _vm.products,
              productCategories: _vm.productCategories,
              posMods: _vm.posProducts,
              posProducts: _vm.posProducts,
              imageServer: _vm.imageServer,
              integrated: _vm.pos !== "grab",
              availability: _vm.availabilityDraft,
            },
            on: {
              productUpdated: _vm.handleProductUpdate,
              removeModSet: _vm.handleRemoveModSet,
              removeModGroup: _vm.onRemoveModGroup,
              removeMod: _vm.onRemoveMod,
              addTag: _vm.onAddTag,
              removeTag: _vm.onRemoveTag,
              uploadImage: _vm.onUploadImage,
              removeSub: _vm.onRemoveSub,
              saveModGroup: _vm.onSaveModGroup,
              onToggleModGroup: _vm.onToggleModGroup,
              availabilityChange: function ($event) {
                _vm.availabilityDraft = $event
              },
            },
            model: {
              value: _vm.product,
              callback: function ($$v) {
                _vm.product = $$v
              },
              expression: "product",
            },
          }),
          _vm.product.id && _vm.canEdit
            ? _c("line-item", { staticClass: "col-12 height-xs--12" }, [
                _c("div", { staticClass: "row no-gutters" }, [
                  _c("div", { staticClass: "col" }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "row no-gutters height-xs--12 align-items-center",
                      },
                      [
                        _c(
                          "text-button",
                          {
                            staticClass: "block-xs--sm-left",
                            attrs: { type: "delete" },
                            nativeOn: {
                              click: function ($event) {
                                _vm.deleteModalActive = true
                              },
                            },
                          },
                          [_vm._v("Delete product")]
                        ),
                      ],
                      1
                    ),
                  ]),
                ]),
              ])
            : _vm._e(),
          _vm.deleteModalActive
            ? _c(
                "editing-modal",
                { attrs: { size: "sm" } },
                [
                  _c(
                    "alert-dialog",
                    {
                      attrs: {
                        title: "Delete Product?",
                        onExit: () => (_vm.deleteModalActive = false),
                        actions: _vm.deleteActions,
                      },
                    },
                    [
                      _vm._v(
                        "This will delete " +
                          _vm._s(_vm.product.inventoryItemName) +
                          ". Are you sure? You can also disable it instead."
                      ),
                    ]
                  ),
                ],
                1
              )
            : _vm._e(),
          _c("unload-confirm", {
            attrs: {
              onExit: _vm.handleLeaveModalExit,
              leaveAction: _vm.leaveConfirmationAction,
              actions: _vm.leaveActions,
            },
          }),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }